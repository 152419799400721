import React, { forwardRef } from 'react'
import { PaymentSuccess as Root } from '../../components/PaymentSuccess'
import { Dialog } from '@platform-ui/components/Dialog'
import { Screen } from '@platform-ui/components/Screen'
import { useStore } from '../../store'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { observer } from 'mobx-react-lite'

export interface MainComponentProps {
  className?: string
}
export const MainComponent = observer(
  forwardRef<HTMLDivElement, MainComponentProps>((props, ref) => {
    const { result, close } = useStore()
    return (
      <Root
        {...props}
        ref={ref}
        onClose={close}
        subTitle={result.subTitle}
        details={result.details}
        icon={result.icon}
        title={result.title}
      />
    )
  })
)

export type PaymentSuccessProps = {}

export const PaymentSuccess = observer<PaymentSuccessProps>(() => {
  const { config } = useAppContext<AppContext>()
  const { isOpen, close } = useStore()

  return config.device.isMobile ? (
    <Screen
      isOpen={isOpen}
      onClose={close}
      isShowCloseIcon={false}
      backgroundColor="custom/bgPaymentSuccess"
      isFocusTrapped={false}
    >
      <MainComponent />
    </Screen>
  ) : (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      isShowCloseIcon={false}
      backgroundColor="custom/bgPaymentSuccess"
      rounded="r12"
      maxBoxSize={'s70'}
      isFocusTrapped={false}
    >
      <MainComponent />
    </Dialog>
  )
})
