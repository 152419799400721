import styled from '@emotion/styled'
import { Body14, Body16, Headline16, Headline32 } from '@platform-ui/typography'
import { FavoriteBorderIcon } from '@platform-ui/icons/FavoriteBorderIcon'
import { RepeatIcon } from '@platform-ui/icons/RepeatIcon'
import { ShareIcon } from '@platform-ui/icons/ShareIcon'

export const Root = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '16px 16px 24px 16px',

  [`${theme.media.md}`]: {
    padding: '64px 48px 40px 48px',
  },
}))

export const ActionList = styled.div(({ theme }) => ({
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'center',
  [`> button`]: {
    marginLeft: '8px',
    ':first-of-type': {
      marginLeft: '0',
    },
  },
}))

export const Footer = styled.div(({ theme }) => ({
  paddingTop: '200px',
  width: '100%',
}))

export const DescriotionText = styled(Body16)(({ theme }) => ({
  color: theme.color['text/tertiary'],
}))

export const CurrencyText = styled(Headline32)(({ theme }) => ({
  marginTop: '16px',
  color: theme.color['text/tertiary'],
}))

export const ImageWrapper = styled.div(() => ({
  marginTop: '32px',
}))

export const ImageCustom = styled.img(() => ({
  objectFit: 'cover',
  borderRadius: '50%',
}))

export const Label = styled(Body14)(({ theme }) => ({
  color: theme.color['text/tertiaryTransparent'],
}))

export const MainText = styled(Headline16)(({ theme }) => ({
  marginTop: '4px',
  color: theme.color['text/tertiary'],
}))

export const TextContainer = styled.div(({ theme }) => ({
  marginTop: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const Main = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 'auto',
  marginBottom: 'auto',
}))

export const RepeatIconCustom = styled(RepeatIcon)(({ theme }) => ({
  width: theme.iconSize.s10,
  height: theme.iconSize.s10,
  [`${theme.media.md}`]: {
    width: theme.iconSize.s12,
    height: theme.iconSize.s12,
  },
}))

export const FavoriteBorderIconCustom = styled(FavoriteBorderIcon)(({ theme }) => ({
  width: theme.iconSize.s10,
  height: theme.iconSize.s10,
  [`${theme.media.md}`]: {
    width: theme.iconSize.s12,
    height: theme.iconSize.s12,
  },
}))

export const ShareIconCustom = styled(ShareIcon)(({ theme }) => ({
  width: theme.iconSize.s10,
  height: theme.iconSize.s10,
  [`${theme.media.md}`]: {
    width: theme.iconSize.s12,
    height: theme.iconSize.s12,
  },
}))
