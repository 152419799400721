import React, { forwardRef } from 'react'
import { Button } from '@platform-ui/components/Button'
import { IconLabelButton } from '@/components/IconLabelButton'

import HomeBankImage from './assets/HomeBank.png'
import {
  ActionList,
  CurrencyText,
  DescriotionText,
  ImageCustom,
  ImageWrapper,
  Label,
  Main,
  MainText,
  Root,
  TextContainer,
  Footer,
  RepeatIconCustom,
  FavoriteBorderIconCustom,
  ShareIconCustom,
} from './styled'

export type PaymentSuccessProps = {
  onClose: () => void
  className?: string
  subTitle: string
  title: string
  icon: string
  details: {
    label: string
    value: string
  }[]
}

export const PaymentSuccess = forwardRef<HTMLDivElement, PaymentSuccessProps>((props, ref) => {
  const { onClose, details, subTitle, title, icon } = props

  return (
    <Root ref={ref}>
      <Main>
        <DescriotionText>{subTitle}</DescriotionText>
        <CurrencyText>{title}</CurrencyText>
        <ImageWrapper>
          <ImageCustom
            alt={title}
            src={icon || HomeBankImage}
            width={80}
            height={80}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src = HomeBankImage
            }}
          />
        </ImageWrapper>
        {details.map((item, key) => {
          return (
            <TextContainer key={key}>
              <Label>{item.label}</Label>
              <MainText>{item.value}</MainText>
            </TextContainer>
          )
        })}
        {/*<ActionList>*/}
        {/*  <IconLabelButton onClick={onClose} icon={<RepeatIconCustom />}>*/}
        {/*    Создать автоплатеж*/}
        {/*  </IconLabelButton>*/}
        {/*  <IconLabelButton onClick={onClose} icon={<FavoriteBorderIconCustom />}>*/}
        {/*    Сохранить шаблон*/}
        {/*  </IconLabelButton>*/}
        {/*  <IconLabelButton onClick={onClose} icon={<ShareIconCustom />}>*/}
        {/*    Сохранить чек*/}
        {/*  </IconLabelButton>*/}
        {/*</ActionList>*/}
      </Main>
      <Footer>
        <Button onClick={onClose} isFullWidth>
          Готово
        </Button>
      </Footer>
    </Root>
  )
})
